@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;600;700&display=swap");
:root {
  --header-color: #363636;
  --header-gold-color: #eec046;
  /* --header-links-color:#646464; */
  --transition-duration: 0.3s;
  --transition-delay: 0.1s;
}
@font-face {
  font-family: "main-font";
  src: url(./assets/fonts/creata-light.otf);
}
body {
  margin: 0;
}

* {
  font-family: "Montserrat", sans-serif;
}
h2 {
  font-size: 18px !important;
  text-transform: uppercase !important;
  font-weight: 400 !important;
  line-height: 1.2 !important;
}
.container {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
/* Original Css  */
.about-heading-css::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  background-color: black;
  height: 2px;
  width: 30px;
}
.realisation-heading-css::before{
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  background-color: black;
  height: 2px;
  width: 30px;
}
.certification-heading-css::before{
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  background-color: black;
  height: 2px;
  width: 30px;
}
.partenaire-heading-css::before{
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  background-color: black;
  height: 2px;
  width: 30px;
}
.Local-heading-css::before{
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  background-color: black;
  height: 2px;
  width: 30px;
}
/* contactez nous css  */
.googlmap-styling{
  height: 300px ;
  width: 100%;
}
.form-styling {
  background-image: url(./assets/image/marbel.jpg);
  max-width: 350px !important;
  border: 1px solid #e0d9d9;
}
.form-styling div label{
  font-size: 14px;
}
.form-styling div input{
  border: none;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 1px 1px 4px gray;
}
.form-styling div input::placeholder{
  font-size: 14px;
}
.form-styling div textarea{
  border: none;
  border-radius: 5px;
  outline: none;
  width: 100%;
  height: 100px !important;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 1px 1px 4px gray;
}
.form-styling div textarea::placeholder{
  font-size: 14px;
}
.Contactez-nous-styling {
  background-image: url(./assets/image/appelnous.jpg);
  background-size: 100%;
}

/* end contactez nous  */

.third-section-card h3 {
  font-size: 20px;
}

.third-section-card p {
  font-size: 16px;
  opacity: 0.8;
}
.third-section-card {
  border-bottom: 1px solid #e6e5e5;
}

.last-card {
  border: none !important;
}

/* second section */
.second-section {
  margin-top: 70px;
}
.second-section main h2 {
  font-size: 26px;
  line-height: 1.2;
}
.second-section main p {
  line-height: 1.8;
  max-width: 970px;
  color: #666666;
}
/* end second section  */

/* Partenaire  */
.partenaire-swiper {
  height: 150px;
}
.partenaire-icon {
  height: 60px;
  width: 150px;
}
/* end partenaire */

/* end original css  */

/* Header css  */
.magistone-logo-css {
  width: 60px;
}
.header-links-css a {
  font-size: 12px;
}
.header-links-css a:hover{
  color: gray !important;
  transition-duration: var(--transition-duration);
  transition-delay: var(--transition-delay);
}
/* end Header css  */

.header-wrapper {
  background-color: var(--header-color);
}
.header-devis-gratuit-css {
  border: 1px solid var(--header-gold-color);
  color: var(--header-gold-color);
  cursor: pointer;
  font-size: 12px !important;
  white-space: nowrap;
}
.header-devis-gratuit-css:hover {
  color: white;
  border: 1px solid white;
  transition-duration: var(--transition-duration);
  transition-delay: var(--transition-delay);
}

/* Slide show css  */

.first-image {
  width: 100%;
}
.second-image {
  width: 100%;
}
.third-image {
  width: 100%;
}

/* end slide show css  */

/* plan de travail css  */

.plans-de-travail-css h2::before {
  content: "";
  position: absolute;
  left: 0;
  top: 40%;
  background-color: black;
  height: 2px;
  width: 30px;
}
.plansdetravail-swiper-css .swiper-button-prev {
  background-color: var(--header-gold-color);
  opacity: 0.8;
  padding-left: 15px;
  padding-right: 15px;
  left: 0;
  height: 30px;
}
.plansdetravail-swiper-css .swiper-button-next {
  background-color: var(--header-gold-color);
  opacity: 0.8;
  padding-left: 15px;
  padding-right: 15px;
  right: 0;
  height: 30px;
}
.plansdetravail-swiper-css .swiper-button-prev::after,
.plansdetravail-swiper-css .swiper-button-next::after {
  font-size: 14px;
  color: black;
  font-weight: 600;
}

/* end plan de travail css  */

/* partenaire mobilier  */

.partenaire-mobilier-wrapper h2::before {
  content: "";
  position: absolute;
  left: 0;
  top: 40%;
  background-color: black;
  height: 2px;
  width: 30px;
}
.partenaire-mobilier-css p {
  font-size: 11px;
}
.partenaire-mobilier-css a {
  color: var(--header-gold-color);
  font-size: 10px;
  border: 1px solid var(--header-gold-color);
}
.partenaire-mobilier-img {
  overflow: hidden;
}
.partenaire-mobilier-img img {
  max-width: none !important;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

/* end partenaire mobilier  */

/* guide conseil css  */
.guide-text-css figure{
  overflow: hidden;
  height: 215px;
}
.guide-text-css figure img{
  min-height: 100% !important;
}
.blog-text-time-css{
  padding-bottom: 10px;
  font-size: 12px;
  border-bottom: 1px solid gray;
}
.blog-h3-css{
  font-size: 16px;
  cursor: pointer;
}
.guide-text-css p {
  font-size: 12px;
}
.lire-plus-css {
  font-size: 12px;
  color: var(--header-gold-color);
  text-decoration: none;
}
/* end guide conseil css  */

/* footer style css  */
footer {
  background-color: var(--header-color);
}
.footer-heading-css {
  color: var(--header-gold-color);
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}
.footer-heading-css::before {
  position: absolute;
  content: "";
  background-color: var(--header-gold-color);
  height: 2px;
  width: 10px;
  left: 0;
  top: 50%;
}
.footer-nav-css a {
  font-size: 12px;
}
small {
  font-size: 10px !important;
}
/* end footer css  */
.contact-wrapper-css{
  position: fixed;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
  z-index: 2100;
}
.black-background-css{
  position: fixed;
  height: 1000px;
  width: 100%;
  z-index: 2050;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
  opacity: 0.4;
}
.side-bar-wrapper{
  background-color: var(--header-color) !important;
}
.side-bar-wrapper figure{
  border-bottom: 1px solid gray;
}
.aside-bar a{
  font-size: 12px;
}
.aside-bar nav a{
  font-size: 10px;
}
.logo-sidebare{
  width: 80px;
}
@media (min-width: 768px) {
  h2 {
    font-size: 30px !important;
  }
  .plans-de-travail-css h2::before {
    top: 45%;
  }
}

@media (min-width: 992px) {
  .googlmap-styling{
    height: 100% ;
    width: 100%;
  }
  .partenaire-mobilier-img img{
    width: 100%;
  }
}
.lazyloading-wrapper{
  background-color: var(--header-color);
}
.lazy-loading-bullets div{
  height: 10px;
  width: 10px;
  background-color: white;
  border-radius: 100%;
  margin-right: 10px;
  animation: 2s ease-in-out bullets-animation infinite;
}
@keyframes bullets-animation {
  0%{
    opacity: 1;
  }
  50%{
    opacity: 0.5;
  }
  100%{
    opacity: 0.8;
  }
}